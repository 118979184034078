.horizontal-scroll {
    display: grid;
    grid-auto-columns: min-content;
    grid-gap: u(2);
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    -webkit-overflow-scrolling: touch;
    padding: 0 0 u(2) u(2);
    max-width: 100vw;

    /* Hide scrollbar */
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    &:after {
        content: '';
        width: 1px;
    }

    // &--avatars {
    //     grid-auto-columns: u(10);
    // }

    // &--thumbnails {
    //     grid-auto-columns: u(15);
    // }

    // &--tutorials {
    //     grid-auto-columns: u(22);
    // }

    // &--contacts {
    //     grid-gap: 0;
    //     grid-auto-columns: 92px;
    //     padding: 0 u(1) u(1);

    //     &:after {
    //         content: '';
    //         width: u(1);
    //     }
    // }
}
