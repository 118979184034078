.onboarding-page {
    --footer-height: 156px;
    background-image: url('/assets/images/top-cover-bg-mobile.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: max-content;
    padding-bottom: calc(var(--footer-height) + 20px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    @media (min-width: $bp-tablet-portrait) {
        --footer-height: 143px;
        background-image: url('/assets/images/top-cover-bg.svg');
        align-items: center;
        padding-top: 20px;
        overflow-y: auto;
    }
}

.dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100%);
    height: auto;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -17.81%, #ffffff 50.2%);
    padding: 0 32px;

    @media (min-width: $bp-tablet-portrait) {
        width: 600px;
        padding: 0 135px;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 4px 4px rgba(120, 120, 120, 0.25);
        backdrop-filter: blur(15px);
        border-radius: 20px;
        min-height: 365px;
    }

    .logo-box {
        margin-top: 71px;
        width: 285px;

        @media (min-width: $bp-tablet-portrait) {
            margin-top: 24px;
            width: 300px;
        }
    }

    .__title {
        margin-top: 16px;
        font-family: $font-heading;
        font-size: 21px;
        line-height: 30px;
        color: $color-kinzoo-black;
        font-weight: 700;
        margin-bottom: 18px;
        text-align: center;

        @media (min-width: $bp-tablet-portrait) {
            margin-bottom: 24px;
            margin-top: 35px;
            font-size: 24px;
            line-height: 31px;
        }
    }

    .__content {
        font-size: 18px;
        line-height: 26.1px;
        font-weight: 500;
        text-align: center;
        color: $color-kinzoo-black;
        margin-bottom: 32px;

        @media (min-width: $bp-tablet-portrait) {
            margin-bottom: 24px;
        }

        img {
            @media (min-width: $bp-tablet-portrait) {
                width: 125px;
            }
        }
    }

    .__action-btn {
        margin-bottom: 16px;
    }

    .__action-text {
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        text-align: center;
        color: $color-kinzoo-black;
        margin-bottom: 40px;

        a {
            font-size: 18px;
            line-height: 26.1px;
            font-weight: 700;
            color: $color-kinzoo-purple;
            cursor: pointer;
        }
    }
}
