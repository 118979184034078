.dialog-connecting,
.activity-loading {
    @include flex-center;

    flex-direction: column;

    .__title {
        margin-top: 48px;
        font-family: $font-heading;
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        color: $color-white;
    }

    .__action-btn {
        width: 100%;
        margin-top: 28px;
        min-width: 300px;
    }

    .__animation {
        @include flex-center();
    }
}
