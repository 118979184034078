.logo {
    $logo-width: 330px;
    $logo-ratio: 975 / 224;

    flex-shrink: 0;
    width: $logo-width * 0.875;
    height: $logo-width / $logo-ratio;
    margin: 0;
    background-image: url('/assets/images/kinzoo_together_logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -999em;

    @media (min-width: $bp-tablet-portrait) {
        width: $logo-width;
    }
}
