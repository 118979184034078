// Utility classes
.hidden {
    display: none !important;
}

.--text-center {
    text-align: center;
}

.--mb-2 {
    margin-bottom: 1rem;
}

fa-icon {
    &.v-center {
        .svg-inline--fa {
            vertical-align: text-bottom;
        }
    }
    &.v-top {
        .svg-inline--fa {
            vertical-align: text-top;
        }
    }
    &.square {
        .svg-inline--fa {
            width: 1em;
            height: 1em;
        }
    }
}
