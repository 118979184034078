.divider {
    text-align: center;
    font-weight: bold;
    font-size: calc-rem(14px);
    margin: u(3) 0;
    text-transform: uppercase;
    color: #555;

    &:before,
    &:after {
        content: ' – ';
    }

    &.--light {
        color: #aaa;
    }
}

.divider-full-line {
    display: flex;
    column-gap: 10px;
    margin: 14px 0;
    font-size: 1rem;
    line-height: 2;
    color: #bdbdbd;

    &:before,
    &:after {
        content: '  ';
        display: inline-block;
        height: 1px;
        background-color: #bdbdbd;
        width: 50%;
        margin-top: 15px;
    }
}
