/* Museo Sans */
@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    src: url('/assets/fonts/MuseoSans-100.otf') format('opentype');
}
@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/MuseoSans-300.otf') format('opentype');
}
@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/MuseoSans-500.otf') format('opentype');
}
@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/MuseoSans-700.otf') format('opentype');
}
@font-face {
    font-family: 'Museo Sans';
    font-style: normal;
    font-weight: 900;
    src: url('/assets/fonts/MuseoSans-900.otf') format('opentype');
}
@font-face {
    font-family: 'Dyna Puff';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/DynaPuff-SemiBold.ttf') format('opentype');
}
