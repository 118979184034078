.book-thumb {
    cursor: pointer;
    @include card-width();

    &__image-wrapper {
        position: relative;
        width: 100%;
    }

    &__user-avatar {
        $size: 40px;
        position: absolute;
        top: u(1);
        right: u(1);
        width: $size;
        height: $size;
        border-radius: 100%;
        background-color: #fff;
        background-position: center;
        background-size: 70%;
        background-repeat: no-repeat;
    }

    &__user-fav {
        $size: 34px;
        position: absolute;
        top: u(1);
        right: u(1);
        width: $size !important;
        height: $size !important;

        @media (min-width: $bp-tablet-portrait) {
            $size: 42px;
            width: $size !important;
            height: $size !important;
        }
    }

    &__image {
        width: 100%;
        @include card-height();
        border-radius: 15px;
        border: 1px solid $color-menu-grey;
        background-size: cover;
        background-position: center;
        &.--top {
            background-position: top;
        }
    }

    &__purchase-state {
        position: absolute;
        bottom: u(1);
        right: u(1);
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__btn-container {
            width: 46px;
            height: 37px;
            border-radius: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: rgba(21, 22, 24, 0.5);

            &.--merge-corner {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .--bg-btn {
                $size: 26px !important;

                &.--buy {
                    background-image: url('/assets/images/buy-dollar.svg');
                }

                &.--purchased {
                    background-image: url('/assets/images/purchased.svg');
                }

                &.--rented {
                    background-image: url('/assets/images/rented.svg');
                }

                &.--expired {
                    background-image: url('/assets/images/expired.svg');
                }
            }
        }

        &__rental-expiry {
            max-width: 100%;
            height: 37px;
            padding: 9px 6px;
            border-radius: 10px;
            border-bottom-right-radius: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: rgba(21, 22, 24, 0.7);
            color: white;
            overflow: hidden;
            font-size: 16px;
            font-weight: 500;
        }
    }

    &__content-lock {
        position: absolute;
        top: 16px;
        left: 16px;
        height: 36px;
        padding: 8px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        font-size: 20px;
        color: $color-darkest-grey;
        background: rgba(111, 112, 113, 0.3);
        &.--green {
            color: $color-new-accent-green;
            background: rgba(32, 32, 32, 0.3);
        }
    }

    &.--featured {
        --card-aspect-ratio-multiplier: 0.58;

        .book-thumb__image {
            width: 100%;
            @include card-height();
            border-radius: 20px;
        }
    }

    &--placeholder {
        .book-thumb__image {
            background-color: $color-grey;
        }
    }
}
