.modal {
    $header-height: 56px;

    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - #{$modal-top-margin});
    margin: $modal-top-margin auto 0;

    @media (min-width: $bp-tablet-portrait) {
        max-width: 400px;
        height: auto;
        max-height: calc(100vh - #{$modal-top-margin * 2});
    }

    &,
    .nsm-content {
        box-shadow: none;
        background-color: transparent;
        padding: 0;
    }

    &__header {
        position: relative;
        height: $header-height;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-right-radius: $modal-border-radius;
        border-top-left-radius: $modal-border-radius;
        background-color: #0c7ff2;
        background: linear-gradient(to right, #0cb9f2 0%, #0c7ff2 100%);
    }

    &__title {
        color: $color-white;
        font-family: $font-heading;
    }

    &__close,
    &__action {
        position: absolute;
        display: flex;
        align-items: center;
        top: 0px;
        height: $header-height;
        cursor: pointer;
        text-transform: uppercase;
        padding: 0 20px;
        font-family: $font-heading;
        font-size: 14px;
        color: $color-white;
    }

    &__close {
        left: 0px;
    }

    &__action {
        right: 0px;
    }

    &__content {
        @include bg;

        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: $modal-padding-sm;
        text-align: left;

        @media (min-width: $bp-tablet-portrait) {
            flex-grow: 0;
            border-bottom-right-radius: $modal-border-radius;
            border-bottom-left-radius: $modal-border-radius;
            padding: $modal-padding-lg;
        }

        p,
        .__p {
            margin: u(1.5) 0;
            text-align: left;
            font-size: calc-rem(18px);
            line-height: 1.3;
            text-rendering: geometricPrecision;
        }

        a {
            color: $color-blue-light;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &--wide {
        @media (min-width: $bp-tablet-portrait) {
            height: 100%;
            max-width: $modal-wide-width;
            max-height: calc(100vh - #{$modal-top-margin * 2});
        }
    }

    &--ultra-wide {
        @media (min-width: $bp-tablet-portrait) {
            height: 100%;
            max-width: $modal-ultra-wide-width;
            max-height: calc(100vh - #{$modal-top-margin * 2});
        }
    }

    &--wait {
        z-index: $z-wait-dialog !important;
    }

    &--stripe {
        z-index: $z-payment-modal !important;
    }
}
