.mat-mdc-snack-bar-container {
    .mdc-snackbar {
        &__surface {
            padding-right: 0;
            border-radius: 10px !important;
            background-color: rgba(21, 22, 24, 0.8) !important;
            min-width: unset !important;
            width: auto !important;
        }
        &__label {
            padding: 20px 34px 20px 20px !important;
        }
    }
}
