$form-label-color: #000;
$form-label-font: $font-heading;

.form {
    $border-radius: 10px;

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    .ng-invalid.ng-touched {
        border: 1px solid $color-new-accent-red;
    }

    label,
    .label {
        display: block;
        margin-bottom: 10px;
        text-align: left;
        color: $color-kinzoo-black;
        font-size: 16px;
        line-height: 23px;
        font-family: $font-heading;
        font-weight: normal;
        font-weight: 700;
    }

    input[type='text'],
    input[type='password'] {
        display: block;
        width: 100%;
        height: 53px;
        margin: u(1) 0 u(1.5);
        border-radius: $border-radius;
        border: none;
        background-color: $color-lighter-grey;
        padding: 0 u(1) 0 28px;
        color: $color-kinzoo-black;
        font-family: $font-heading;
        font-weight: 700;
        font-size: calc-rem(16px);
        line-height: 19px;
        outline: none;

        &::placeholder {
            color: $color-grey;
        }

        @media (min-width: $bp-tablet-portrait) {
            font-size: calc-rem(18px);
            line-height: 21px;
        }
    }

    textarea {
        height: u(16);
        margin-bottom: u(1);
        padding: 24px;
        width: 100%;
        border: none;
        background-color: $color-lighter-grey;
        border-radius: 10px;
        color: $color-kinzoo-black;
        font-family: $font-heading;
        font-weight: 700;
        font-size: calc-rem(16px);
        line-height: 19px;
        resize: none;

        @media (min-width: $bp-tablet-portrait) {
            font-size: calc-rem(18px);
            line-height: 21px;
        }
    }

    .input-with-icon {
        position: relative;
        .input-icon {
            position: absolute;
            top: 15px;
            right: 24px;
        }
    }

    &__input-errors {
        margin-bottom: u(1.5);
        font-size: calc-rem(14px);
        margin-top: u(-1);
        margin-left: u(1);
        color: $color-new-accent-red;
    }

    &__input-action {
        display: flex;
        margin: u(1) 0 u(1.5);

        input {
            margin: 0 !important;
        }

        .__action {
            @include flex-center;

            flex-shrink: 0;
            width: u(6);
            height: u(6);
            margin-left: u(0.5);
            outline: none;
            border-radius: $border-radius;
            border: none;
            background-color: transparent;
            color: $color-blue-light;
            cursor: pointer;

            .svg-inline--fa {
                font-size: calc-rem(30px);
            }

            &:hover {
                background-color: rgba($color-white, 0.4);
            }

            &[disabled] {
                color: rgba($color-blue-light, 0.35);
            }
        }
    }

    select {
        display: block;
        width: 100%;
        padding: 15px 10px;
        border-radius: 10px;
        background-color: #efefef;
        border: 0;
        font-size: 16px;
        line-height: 19px;

        &.--has-values {
            background-color: #fff;
            border: 1px solid #521baf;
        }
    }

    .form--field {
        display: flex;
        flex-direction: column;

        mat-form-field {
            &.mat-mdc-form-field {
                display: inline-block;
                position: relative;
                text-align: left;
                font-family: Roboto, 'Helvetica Neue', sans-serif;
                font-size: inherit;
                font-weight: 400;
                line-height: 1.125;
                letter-spacing: normal;
            }
            .mdc-text-field {
                padding: 0;
            }
            .mdc-text-field--filled:not(.mdc-text-field--disabled) {
                background-color: transparent;
            }
            .mdc-line-ripple,
            .mat-mdc-form-field-subscript-wrapper {
                display: none;
            }
            .mat-form-field-ripple {
                transition: unset;
                background-color: unset;
            }

            &.--hide-input {
                .form--field__icon,
                input {
                    display: none;
                }
            }

            &.--selected {
                .mat-mdc-form-field-flex {
                    background-color: $color-kinzoo-purple;
                    color: $color-white;
                }
            }
        }

        .mat-mdc-text-field-wrapper {
            padding-bottom: 0 !important;
            .mat-mdc-form-field-focus-overlay {
                display: none;
            }
        }

        &__icon {
            font-size: 24px;
            padding-right: 18px;
            display: none;

            @media (min-width: $bp-tablet-portrait) {
                display: inline;
            }
        }

        &__selected-label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            column-gap: 5px;

            @media (min-width: $bp-tablet-portrait) {
                justify-content: space-between;
            }

            &__text {
                color: $color-white;
            }

            &__count {
                width: 24px;
                height: 24px;
                background-color: $color-white;
                color: $color-kinzoo-purple;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                @media (min-width: $bp-tablet-portrait) {
                    width: 28px;
                    height: 28px;
                }
            }
        }
        mat-select {
            margin: auto 0 !important;
            &.mat-mdc-select {
                color: inherit;
                font-family: inherit;
                line-height: inherit;
                font-size: inherit;
                font-weight: inherit;
                letter-spacing: inherit;
                height: 1.125em;
            }
        }

        .mat-mdc-select-trigger {
            display: inline-flex;

            .selected__label {
                padding: 0 12px;
                font-size: 16px;
                line-height: 31px;
                font-weight: 700;
                color: #ffffff;
                background-color: #521baf;
                border-radius: 50px;
                margin-right: 4px;
                display: inline-block;

                .selected--clear-icon {
                    padding-left: 13px;
                    font-size: 12px;
                }
            }

            .selected__count {
                padding: 0 12px;
                font-size: 16px;
                line-height: 31px;
                font-weight: 700;
            }
        }

        .mat-mdc-form-field-flex {
            align-items: center !important;
            height: 44px !important;
            padding: 0 !important;
            background-color: $color-lighter-grey;
            border-radius: 10px;
            font-size: 16px;
            text-align: center;
            font-weight: 700;

            @media (min-width: $bp-tablet-portrait) {
                height: 60px !important;
                font-size: 18px;
                text-align: left;
            }

            .mat-mdc-form-field-infix {
                display: flex !important;
                border: 0 !important;
                padding: 0 12px;
                height: 100%;

                @media (min-width: $bp-tablet-portrait) {
                    padding: 0 20px;
                }
            }
        }

        .mat-mdc-input-element::placeholder {
            color: $color-kinzoo-black;
        }
    }

    mat-label {
        font-family: $form-label-font;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $form-label-color;
        text-transform: capitalize;
        padding-left: 20px;
        margin-bottom: 16px;
    }
}

.mat-mdc-option {
    padding: 20px 24px !important;
    flex-direction: row-reverse;
    color: $color-kinzoo-black !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    height: 3em;
    .mat-pseudo-checkbox {
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        background: $color-new-white;
        border: 2.52632px solid #383838;
        border-radius: 5.05263px;
    }
    &.mdc-list-item--selected {
        background: $color-shade-purple !important;
        border-radius: 10px !important;
        margin-top: 8px;
    }
    .mdc-list-item__primary-text {
        color: $color-kinzoo-black !important;
        font-weight: 600 !important;
    }
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
        background-color: $color-kinzoo-purple;
        background-image: url('/assets/icons/svgs/checked.svg');
        background-repeat: no-repeat;
        background-position: center;
        border: none;

        &::after {
            content: unset;
        }
    }
    .mat-mdc-select-arrow {
        border-left: 15px solid transparent !important;
        border-right: none !important;
        border-top: 15px solid transparent !important;
        border-image-source: url('/assets/icons/svgs/chevron-down.svg') !important;
        border-image-repeat: stretch !important;
    }
}

mat-checkbox {
    --mdc-checkbox-state-layer-size: 28px;
    border-radius: 10px;
    background: #ffffff;
    height: 58px;
    padding: 15px 24px;
    .mdc-checkbox {
        padding: 0 !important;
        margin: 0 !important;
        height: 28px;
        width: 28px;
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
            ~ .mdc-checkbox__background {
            border-radius: 50% !important;
        }
    }
    &.mat-mdc-checkbox {
        display: block !important;
        .mdc-checkbox__ripple {
            display: none;
        }
    }
    .mdc-checkbox__background {
        border-radius: 50%;
        height: 28px !important;
        width: 28px !important;
        top: 0 !important;
        left: 0 !important;
        .mdc-checkbox__checkmark {
            padding: 4px;
        }
    }
    .mat-mdc-checkbox-touch-target {
        height: 28px !important;
        width: 28px !important;
    }
    &.mat-mdc-checkbox-checked {
        background: $color-shade-purple;

        .mdc-checkbox__background {
            border: none !important;
            background-color: $color-kinzoo-purple !important;
        }
        .mdc-label {
            color: $color-kinzoo-purple !important;
        }
    }
    .mat-checkbox-layout,
    .mdc-form-field {
        display: flex !important;
        flex-direction: row-reverse;
        justify-content: space-between;
        height: 28px;
        .mat-checkbox-inner-container {
            height: 28px !important;
            width: 28px !important;
            .mdc-checkbox__checkmark {
                border-radius: 50% !important;
            }
        }

        .mdc-label {
            font-size: 18px;
            line-height: 21px;
            color: $color-kinzoo-black;
            font-weight: 600;
            width: 100%;
            text-align: left;
        }
    }
    /* Override the default ripple color */
    .mdc-checkbox:hover
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
        ~ .mdc-checkbox__background {
        border-color: var(--mdc-checkbox-unselected-icon-color);
    }
    .mdc-checkbox:hover .mdc-checkbox__native-control:checked ~ .mdc-checkbox__ripple {
        display: none;
    }
    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
        border: none !important;
    }
    .mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
    .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
        background: transparent;
    }
}

mat-radio-group {
    display: flex;
    flex-direction: column;
    .mat-mdc-radio-button {
        --mdc-radio-state-layer-size: 28px;
        --mat-radio-checked-ripple-color: $color-kinzoo-purple;
        --mdc-radio-selected-icon-color: $color-kinzoo-purple;
        .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
        .mdc-radio:hover .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
            border-color: $color-kinzoo-purple !important;
        }
    }
    mat-radio-button {
        border-radius: 10px;
        background: #ffffff;
        height: 58px;
        padding: 15px 24px;

        .mdc-form-field {
            justify-content: space-between;
            flex-direction: row-reverse;
            width: 100%;
            height: 28px;
            .mdc-label {
                width: 100%;
                font-size: 18px;
                line-height: 21px;
                color: $color-kinzoo-black;
                font-weight: 600;
                text-align: left;
            }
            .mdc-radio__background {
                &::before {
                    background-color: transparent !important;
                }
            }
        }
        .mat-radio-ripple {
            display: none !important;
        }

        &.mat-mdc-radio-checked {
            background: $color-shade-purple;
            .mdc-radio__outer-circle {
                border-color: $color-kinzoo-purple !important;
            }
            .mdc-radio__inner-circle {
                background-color: $color-kinzoo-purple !important;
            }
            .mdc-label {
                color: $color-kinzoo-purple !important;
            }
        }
    }
}
