.section {
    margin-bottom: u(4);

    &__title-wrapper,
    &__title {
        margin-bottom: u(2);
    }

    &__title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $mobile-container-padding-right 0 $mobile-container-padding-left;

        @media (min-width: $bp-tablet-portrait) {
            padding: 0 $tablet-container-padding-right 0 $tablet-container-padding-left;
        }

        .section__title {
            margin-bottom: 0;
        }
    }

    &__title {
        font-family: $font-heading;
        font-weight: 600;
        font-size: calc-rem(18px);
        color: #000000;
        line-height: 1;
        &__premium-tag {
            font-weight: 600;
            font-size: calc-rem(13px);
            color: $color-darkest-grey;
            line-height: 18px;
            .content-lock {
                &.--green {
                    color: $color-new-accent-green;
                }
            }
        }
    }

    &__title-link {
        color: $color-new-accent-purple;
        font-size: calc-rem(18px);
        line-height: calc-rem(21px);
        letter-spacing: -0.02em;
        font-weight: 600;
        cursor: pointer;
    }

    &__content {
    }
}

app-section-title-portal,
.layout__title {
    display: flex;
    padding: 0 $mobile-container-padding-right 0 $mobile-container-padding-left;
    margin-top: 20px;
    margin-bottom: 13px;
    align-items: center;

    h3 {
        font-family: $font-heading;
        font-weight: 500;
        font-size: 21px;
        margin: 0;

        @media (min-width: $bp-tablet-portrait) {
            font-size: 28px;
        }
    }

    @media (min-width: $bp-tablet-portrait) {
        padding: 0 $tablet-container-padding-right 0 $tablet-container-padding-left;
        margin-top: 12px;
        margin-bottom: 16px;
    }

    .action-container,
    .secondary-action-container {
        display: flex;
        margin-left: 16px;

        @media (min-width: $bp-tablet-portrait) {
            margin-left: 21px;
        }

        button {
            position: relative;
            border: 1px solid #f3f3f3;
            box-shadow: 0px 5px 8px rgba(120, 120, 120, 0.25);
            margin-right: 8px;

            &:last-child {
                margin-left: 0;
            }

            @media (min-width: $bp-tablet-portrait) {
                margin-right: 10px;
            }
        }
        .unread-badge {
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: $color-new-accent-red;
            color: #fff;
            padding: 5px 0;
            text-align: center;
            font-size: 10px;
            line-height: 1;
            border-radius: 50%;
            z-index: 2;
            display: inline-block;
            height: 20px;
            width: 20px;
        }
    }
    .secondary-action-container {
        margin-left: auto;
    }
}

.section__content {
    &__empty-container {
        padding: 0 16px 16px 16px;

        &__box {
            border-radius: 20px;
            background: #ece1ff;
            padding: 16px 16px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 16px;
            max-height: 69px;

            @media (min-width: $bp-tablet-portrait) {
                padding: 16px 48px;
                margin-top: 38px;
                max-height: 80px;
            }

            @media (max-width: $bp-phone-landscape) {
                ::ng-deep button {
                    width: 40px;
                    height: 40px;
                }
            }

            &__text {
                color: $color-kinzoo-purple;
                font-size: 14px;
                line-height: 19px;
                font-weight: 700;
                margin-right: 120px;
                margin-left: 8px;

                @media (min-width: $bp-tablet-portrait) {
                    font-size: 16px;
                    line-height: 19px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }

            &__image {
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: 106px;
                height: 94px;

                @media (min-width: $bp-tablet-portrait) {
                    position: absolute;
                    bottom: 0;
                    right: 24px;
                    width: 140px;
                    height: 118px;
                }

                @media (min-width: $bp-tablet-landscape) {
                    right: 160px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
