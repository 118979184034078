.header {
    display: grid;
    padding: 0 $mobile-container-padding-right 0 $mobile-container-padding-left;
    grid-template-areas:
        'profile-menu . action-box'
        '. sections . ';
    align-items: center;
    min-height: 63px;
    position: relative;

    @media (min-width: $bp-tablet-portrait) {
        grid-template-areas: 'profile-menu sections action-box';
        grid-template-columns: 1fr auto 1fr;
        padding: 0 $tablet-container-padding-right 0 $tablet-container-padding-left;
    }

    &__profile-menu {
        grid-area: profile-menu;
        width: 42px;
        height: 42px;
        background-image: url('/assets/profile-avatar.svg');
        background-repeat: no-repeat;
        background-color: transparent;
        background-size: contain;
        cursor: pointer;

        @media (min-width: $bp-tablet-portrait) {
            width: 52px;
            height: 52px;
        }
    }

    &__favorites-menu {
        grid-area: profile-menu;
        @include flex-center;
        z-index: 1;
        position: relative;
        font-size: calc-rem(24px);
        color: #ffffff;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        background-color: $color-new-accent-orange;
        cursor: pointer;

        @media (min-width: $bp-tablet-portrait) {
            font-size: calc-rem(24px);
            width: 48px;
            height: 48px;
        }

        fa-icon {
            width: 24px;
            height: 24px;
        }
    }

    &__back-icon {
        grid-area: profile-menu;
        display: flex;
        align-items: center;
        z-index: 1;
        position: relative;
        font-size: calc-rem(18px);
        line-height: 21px;
        font-weight: 700;
        color: $color-kinzoo-purple;
        cursor: pointer;

        &__text {
            padding-left: 8px;
        }

        fa-icon {
            width: 18px;
            height: 18px;
        }
    }

    &__sections {
        grid-area: sections;
        display: flex;
        justify-content: space-between;
        grid-column: span 3;
        margin-top: 31px;

        @media (min-width: $bp-tablet-portrait) {
            grid-column: unset;
            margin-top: 0;
            justify-content: center;
        }

        @media (min-width: $bp-tablet-portrait) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
        }

        &__tab {
            $self: &;
            $active-color: $color-new-accent-purple;
            width: 64px;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            position: relative;

            @media (min-width: $bp-tablet-portrait) {
                margin-right: 25px;

                &:last-child {
                    margin-right: 0;
                }
            }

            &:after {
                width: 100%;
                content: '';
                position: absolute;
                background-color: transparent;
                left: 0;
                bottom: 0;
                height: 2px;
                border-radius: 20px;
            }

            &__icon-box {
                height: 32px;
                width: 32px;
                font-size: calc-rem(24px);
                text-align: center;
                color: #8f8f8f;
            }

            &__title {
                font-family: $font-heading;
                font-size: calc-rem(14px);
                line-height: 17px;
                font-weight: 500;
                margin-top: 8px;
                margin-bottom: 6px;
                color: #8f8f8f;
            }

            &--active {
                #{ $self }__icon-box {
                    color: $active-color;
                }

                #{ $self }__title {
                    font-weight: 900;
                    color: $active-color;
                }

                &:after {
                    background-color: $active-color !important;
                }
            }
        }
    }

    &__action-box {
        grid-area: action-box;
        margin-left: auto;
        display: flex;
        align-items: center;
        column-gap: 19px;

        @media (min-width: $bp-tablet-portrait) {
            column-gap: 21px;
        }

        &__help-icon {
            height: 32px;
            width: 32px;
            cursor: pointer;
        }
        &__notification-icon {
            @media (min-width: $bp-tablet-portrait) {
                height: 48px;
                width: 48px;
            }
            height: 40px;
            width: 40px;
            cursor: pointer;
        }
    }
}

.header {
    &.--subpage {
        padding-top: 32px;
        align-items: center;
        grid-template-areas:
            'profile-menu  action-box'
            'header-title .';
        margin-bottom: 1rem;

        @media (min-width: $bp-tablet-portrait) {
            grid-template-areas: 'profile-menu header-title action-box';
            grid-template-columns: 1fr auto 1fr;
            margin-bottom: 2rem;
        }
        &.--has-banner {
            margin-bottom: 0 !important;
        }
    }

    &.--call-state {
        grid-template-areas:
            'profile-menu . action-box'
            '. sections . ';

        @media (min-width: $bp-tablet-portrait) {
            grid-template-areas: 'profile-menu sections action-box';
        }
    }

    &__title {
        grid-area: header-title;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        color: $color-kinzoo-black;
        margin-top: 12px;

        @media (min-width: $bp-tablet-portrait) {
            margin-top: unset;
            font-size: 28px;
            line-height: 33px;
        }

        @media (min-width: $bp-tablet-portrait) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
