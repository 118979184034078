.modal-clean {
    $modal-top-margin: 0px;
    position: relative;
    width: 100%;
    margin: $modal-top-margin auto 0;

    @media (min-width: $bp-tablet-portrait) {
        $modal-top-margin: u(6);
        max-width: 400px;
        margin: $modal-top-margin auto 0;
    }

    &--wide {
        @media (min-width: $bp-tablet-portrait) {
            max-width: $modal-wide-width;
        }
    }

    &--ultra-wide {
        @media (min-width: $bp-tablet-portrait) {
            max-width: $modal-ultra-wide-width;
        }
    }
    &--ultra-wide-xl {
        @media (min-width: $bp-tablet-portrait) {
            max-width: $modal-ultra-wide-xl-width;
        }
    }

    &--full-width {
        @media (min-width: $bp-tablet-portrait) {
            width: 100%;
            max-width: 100%;
            margin: 0;
            max-height: 100% !important;
        }
    }
    &--full-screen {
        @media (min-width: $bp-tablet-portrait) {
            height: calc(100vh - 0px) !important;
            width: 100%;
            max-width: 100%;
            margin: 0;
            max-height: unset !important;
            .modal-clean__content {
                border-radius: 0 !important;
                max-height: unset !important;
            }
        }
        .modal-clean__content {
            height: calc(100vh - 0px);
        }
    }

    &.--centered {
        @media (min-width: $bp-tablet-portrait) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }
    }

    &.--onboarding {
        @media (min-width: $bp-tablet-portrait) {
            background-color: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 4px 4px rgba(120, 120, 120, 0.25);
            backdrop-filter: blur(15px);
            border-radius: 20px;

            .modal-clean__content {
                background-color: transparent;
            }
        }
    }

    &,
    &__content {
        $modal-top-margin: 0px;
        height: calc(100vh - #{$modal-top-margin});

        @media (min-width: $bp-tablet-portrait) {
            $modal-top-margin: u(6);
            height: auto;
            max-height: calc(100vh - #{$modal-top-margin * 2});
        }

        &.--no-outer-padding {
            padding: 0 !important;
        }
    }

    $self: &;

    &.--action-sheet {
        position: absolute;
        bottom: 0;
        animation: animatebottom 0.4s;
        $modal-top-margin: 25vh;
        margin: $modal-top-margin auto 0;
        height: calc(100vh - #{$modal-top-margin});
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: $bp-tablet-portrait) {
            //left: calc(50% - 315px);
        }

        #{ $self }__content {
            $modal-top-margin: 25vh;
            height: calc(100vh - #{$modal-top-margin});
            padding-bottom: 0;
            padding-top: 24px;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;

            @media (min-width: $bp-tablet-portrait) {
                border-radius: 30px;
                height: auto;
                max-height: calc(100vh - #{$modal-top-margin} - 3vh);
            }
        }
    }

    &.--action-sheet-mobile-only {
        @media (max-width: $bp-phone-landscape) {
            position: absolute;
            bottom: 0;
            animation: animatebottom 0.4s;
            $modal-top-margin: 25vh;
            margin: $modal-top-margin auto 0;
            height: calc(100vh - #{$modal-top-margin});
            left: 50%;
            transform: translateX(-50%);

            #{ $self }__content {
                $modal-top-margin: 25vh;
                height: calc(100vh - #{$modal-top-margin});
                padding-bottom: 0;
                padding-top: 24px;
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
            }
        }

        @media (min-width: $bp-tablet-portrait) {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }
    }

    @keyframes animatebottom {
        from {
            bottom: -300px;
            opacity: 0;
        }

        to {
            bottom: 0;
            opacity: 1;
        }
    }

    &__header {
        position: relative;

        .modal__close {
            @include flex-center;
            position: absolute;
            background-image: url('/assets/icons/svgs/times.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 16px;
            height: 16px;
            top: calc(50% - (16px / 2));
            left: 0;
            padding: 0;
            cursor: pointer;
            z-index: 2;

            @media (min-width: $bp-tablet-portrait) {
                width: 20px;
                height: 20px;
                top: calc(50% - (20px / 2));
            }

            &.--back {
                background-image: url('/assets/icons/svgs/arrow-left.svg');
            }
        }

        .modal__title {
            font-family: $font-heading;
            color: $color-kinzoo-black;
            font-size: calc-rem(21px);
            line-height: 26px;
            text-align: center;
            padding-left: 40px;
            padding-right: 40px;
            font-weight: 700;

            @media (min-width: $bp-tablet-portrait) {
                font-size: calc-rem(28px);
                line-height: 41px;
            }
        }

        .modal__secondary-action {
            @include flex-center;
            $size: 32px;
            position: absolute;
            top: calc(50% - (#{$size} / 2));
            right: 20px;
            width: $size;
            height: $size;
            border-radius: $size;
            background: none;
            color: $color-kinzoo-purple;
            cursor: pointer;
            z-index: 2;

            &--label {
                font-size: 18px;
                font-weight: 700;
            }
        }
    }

    &__close {
        //this whole class shouold be removed after all modal close moved into modal header structure
        @include flex-center;
        $size: 32px;
        position: absolute;
        top: 20px;
        left: 32px;
        width: $size;
        height: $size;
        border-radius: $size;
        background: none;
        color: $color-kinzoo-purple;
        cursor: pointer;
        z-index: 2;

        &:hover {
            background-color: $color-kinzoo-purple;
            color: #ffffff;
        }

        &--right {
            left: initial;
            right: 28px;
        }

        &--label {
            font-size: 18px;
            font-weight: 700;
        }
    }

    &__content {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        border-radius: 0;
        padding: u(3);
        background-color: $color-white;

        @media (min-width: $bp-tablet-portrait) {
            border-radius: $modal-border-radius;
            padding: u(4);
        }
    }

    .--no-outer-padding {
        .modal__close {
            left: 24px;
            top: calc(((100% - 24px) / 2) + 24px - 8px);

            @media (min-width: $bp-tablet-portrait) {
                top: calc(((100% - 24px) / 2) + 24px - 10px);
            }
        }
    }
}
