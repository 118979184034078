.dialog-bg {
    @include flex-center;

    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: $color-kinzoo-black;
    padding: u(1);
    z-index: $z-dialog;
    &.--activity-loading {
        z-index: $z-activity-loading;
    }
    &.--over-the-modal {
        z-index: $z-dialog-over-the-modal;
    }
}
