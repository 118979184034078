.product {
    $border-size: 2px;

    border: $border-size solid $color-blue-light;
    border-radius: $border-radius;
    cursor: pointer;
    transition: transform 50ms;

    &:hover {
        background-color: rgba($color-blue-light, 0.05);
    }

    &:active {
        transform: scale(0.98);
    }

    &__main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: u(1.5);
    }

    &__content {
        text-align: left;
        font-weight: bold;
    }

    &__title {
        color: $color-blue-light;
        font-size: calc-rem(20px);
    }

    &__description {
        color: #606060;
        font-size: calc-rem(14px);
        margin-top: u(0.5);
    }

    &__radio {
        $size: 32px;
        --radio-size: #{$size};

        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: var(--radio-size);
        height: var(--radio-size);
        border-radius: 100%;
        border: $border-size solid $color-blue-light;
        background-color: $color-white;
        color: $color-white;
        font-size: calc-rem(14px);

        &--selected {
            --radio-size: #{$size - ($border-size * 2)};
            margin: $border-size;
            border: 0;
            background-color: $color-blue-light;
        }
    }

    &__notice {
        border-radius: 0 0 ($border-radius / 2) ($border-radius / 2);
        padding-top: 2px;
        background-color: $color-blue-light;
        text-align: center;
        color: $color-white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
    }
}
