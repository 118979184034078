.doc-thumb {
    @include card-width();

    cursor: pointer;
    &__image-wrapper {
        position: relative;
        width: 100%;
    }

    &__user-avatar {
        // $size: 40px;

        // position: absolute;
        // top: u(1);
        // right: u(1);
        // width: $size;
        // height: $size;
        // border-radius: 100%;
        // background-color: #fff;
        // background-position: center;
        // background-size: 70%;
        // background-repeat: no-repeat;
    }

    &__image {
        border-radius: 14.185px;
        border: 1px solid #d0d0d0;
        background-size: cover;
        background-position: center;
        width: 100%;
        @include card-height();
    }

    &__title,
    &__subtitle {
        width: 100%;
        text-align: center;
    }

    &__title {
        @include text-ellipsis;
        font-family: $font-body;
        margin-top: 11px;
        font-weight: 300;
        font-size: calc-rem(16px);
        line-height: calc-rem(19.2px);
        letter-spacing: -0.02em;
        color: $color-darkest-grey;
        .content-lock {
            &.--green {
                color: $color-new-accent-green;
            }
        }
    }

    &__subtitle {
        @include text-ellipsis(2);

        margin-top: 4px;
        font-weight: 600;
        font-size: calc-rem(13px);
        line-height: calc-rem(18px);
        color: $color-new-grey-subheader;
    }

    &--activity {
        // .doc-thumb {
        //     $image-border-size: 4px;

        //     &__image {
        //         border: $image-border-size solid $color-white;
        //     }

        //     &__is-new-wrapper {
        //         position: absolute;
        //         top: $image-border-size;
        //         right: $image-border-size;
        //         border-radius: 8px; // Border a little bit smaller than $border-radius as this goes in the border-radius
        //         width: 80px;
        //         height: 80px;
        //         overflow: hidden;
        //     }

        //     &__is-new {
        //         position: absolute;
        //         transform: rotate(45deg);
        //         transform-origin: top center;
        //         right: -50px;
        //         top: 0px;
        //         width: 100px;
        //         background-color: #eb3223;
        //         padding: 26px 0px 4px;
        //         text-align: center;
        //         text-transform: uppercase;
        //         font-family: $font-heading;
        //         font-weight: normal;
        //         font-size: calc-rem(14px);
        //         color: $color-white;
        //         line-height: 1;
        //     }
        // }
    }

    &--placeholder {
        .doc-thumb__image {
            background-color: $color-new-grey-outline;
        }
    }
    &__user-fav {
        $size: 34px;
        position: absolute;
        top: u(1);
        right: u(1);
        width: $size !important;
        height: $size !important;
        @media (min-width: $bp-tablet-portrait) {
            $size: 42px;
            width: $size !important;
            height: $size !important;
        }
    }
    &__purchase-state {
        position: absolute;
        bottom: u(1);
        right: u(1);
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &__btn-container {
            width: 46px;
            height: 37px;
            border-radius: 10px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: rgba(21, 22, 24, 0.5);
            &.--merge-corner {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            .--bg-btn {
                $size: 26px !important;
                &.--buy {
                    background-image: url('/assets/images/buy-dollar.svg');
                }
                &.--purchased {
                    background-image: url('/assets/images/purchased.svg');
                }
                &.--rented {
                    background-image: url('/assets/images/rented.svg');
                }
                &.--expired {
                    background-image: url('/assets/images/expired.svg');
                }
            }
        }

        &__rental-expiry {
            max-width: 100%;
            height: 37px;
            padding: 9px 6px;
            border-radius: 10px;
            border-bottom-right-radius: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: rgba(21, 22, 24, 0.7);
            color: white;
            overflow: hidden;
            font-size: 16px;
            font-weight: 500;
        }
    }
    &__owner {
        $size: 34px !important;
        position: absolute;
        bottom: u(1);
        left: u(1);
        width: $size;
        height: $size;
    }
    &__content-lock {
        position: absolute;
        top: 16px;
        left: 16px;
        height: 36px;
        padding: 8px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        font-size: 20px;
        color: $color-darkest-grey;
        background: rgba(111, 112, 113, 0.3);
        &.--green {
            color: $color-new-accent-green;
            background: rgba(32, 32, 32, 0.3);
        }
    }
}
