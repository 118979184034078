.alert {
    $border-radius: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 54px;
    height: auto;
    border: 0;
    border-radius: 8px;
    padding: 16px;
    background: none;
    font-size: calc-rem(18px);
    font-weight: 700;
    color: $color-white;
    line-height: 19px;
    user-select: none;
    text-decoration: none;
    outline: none;
    z-index: 1;

    &.--wide {
        width: 100%;
    }

    &.--purple {
        background-color: $color-shade-purple;
        color: $color-kinzoo-purple;
    }

    &.--shade-purple {
        background-color: $color-shade-purple;
        color: $color-kinzoo-purple;
    }

    &.--yellow {
        background-color: $color-new-accent-yellow;
        color: $color-kinzoo-black;
    }

    &.--orange {
        background-color: $color-new-accent-orange;
    }

    &.--red {
        background-color: $color-new-accent-red;
    }

    &.--rounded {
        border-radius: $border-radius;
    }

    fa-icon {
        margin-right: calc-rem(16px);
    }
}
