.placeholder {
    $font-size: 13px;
    $placholder-widths: 20 50 80 120 250;

    display: inline-block;
    background-color: $color-grey;
    height: $font-size !important;

    @each $width in $placholder-widths {
        &--#{$width} {
            width: ($width * 1px) !important;
        }
    }

    &--block {
        display: block;
    }

    &--square {
        width: $font-size;
    }

    &--dark {
        background-color: #5c626b;
    }
}
