.button {
    $border-radius: 10px;
    $shadow-size: 4px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 48px;
    border: 0;
    padding: 0 u(1.5) $shadow-size u(1.5);
    background: none;
    line-height: 1;
    cursor: pointer;
    font-size: calc-rem(18px);
    font-weight: bold;
    color: $color-white;
    transition: transform $duration-fast;
    user-select: none;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 1;

    &[disabled] {
        opacity: 0.55;
        cursor: not-allowed;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: $shadow-size;
        border-radius: $border-radius;
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border-radius: 0px 0px $border-radius $border-radius;
        z-index: -2;
        transition: transform $duration-fast;
    }

    &:active {
        transform: translateY($shadow-size);

        &:after {
            transform: translateY(-$shadow-size);
        }
    }

    &.--wide {
        width: 100%;
    }

    &.--blue,
    &--blue {
        $from-color: #0cb9f2;
        $to-color: #0c7ff2;
        $darken: 12%;

        &:before {
            background: linear-gradient(to right, $from-color 0%, $to-color 100%);
        }

        &:after {
            background: linear-gradient(to right, darken($from-color, $darken) 0%, darken($to-color, $darken) 100%);
        }
    }

    &.--orange,
    &--orange {
        $from-color: #f2b90c;
        $to-color: #f2910c;
        $darken: 12%;

        &:before {
            background: linear-gradient(to right, $from-color 0%, $to-color 100%);
        }

        &:after {
            background: linear-gradient(to right, darken($from-color, $darken) 0%, darken($to-color, $darken) 100%);
        }
    }

    &.--grey {
        $bg-color: #e0e0e0;
        $darken: 12%;
        color: #333;

        &:before {
            background-color: $bg-color;
        }

        &:after {
            background-color: darken($bg-color, $darken);
        }
    }

    &.--red {
        $from-color: #f25454;
        $to-color: #d33939;
        $darken: 12%;

        &:before {
            background: linear-gradient(to right, $from-color 0%, $to-color 100%);
        }

        &:after {
            background: linear-gradient(to right, darken($from-color, $darken) 0%, darken($to-color, $darken) 100%);
        }
    }

    &.--white {
        color: #333;
        font-weight: 600;

        &:before {
            background-color: #fff;
        }

        &:after {
            background-color: #d6d6d6;
        }
    }

    &.--empty {
        color: $color-blue-light;
        font-weight: 300;

        &:before {
            background: transparent;
        }

        &:hover:before {
            background-color: #eee;
        }

        &:after {
            background: transparent;
        }
    }
}

.button-v2 {
    $border-radius: 50px;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 0;
    padding: 4px 26px;
    background: none;
    cursor: pointer;
    font-family: $font-body;
    font-size: calc-rem(16px);
    font-weight: bold;
    color: $color-white;
    line-height: 2;
    user-select: none;
    text-decoration: none;
    outline: none;
    z-index: 1;

    &[disabled] {
        cursor: not-allowed;
        background-color: $color-grey !important;
        color: $color-white !important;
    }

    &.--large {
        font-size: 18px;
        line-height: 32px;
        height: 60px;
    }

    &.--medium {
        font-size: 18px;
        line-height: 32px;
        height: 56px;

        &.--mobile-shrink {
            height: 50px;

            @media (min-width: $bp-tablet-portrait) {
                height: 50px;
            }
        }
    }

    &.--wide {
        width: 100%;
    }

    &.--empty {
        color: $color-kinzoo-purple;
        font-weight: 700;

        &:before {
            background: transparent;
        }

        &:hover:before {
            background-color: #eee;
        }

        &:after {
            background: transparent;
        }
    }

    &.--white {
        background-color: $color-white;
    }

    &.--purple-text {
        color: $color-kinzoo-purple;
    }

    &.--border-grey {
        border: 1px solid #e3e5e5;
        color: #090a0a;
    }

    &.--border-purple {
        border: 1px solid $color-kinzoo-purple;
        color: $color-kinzoo-purple;
    }

    &.--purple {
        background-color: $color-new-accent-purple;
    }

    &.--shade-purple {
        background-color: $color-shade-purple;
        color: $color-kinzoo-purple;
    }
    &.--light-purple {
        background-color: $color-light-purple;
        color: $color-kinzoo-purple;
    }

    &.--yellow {
        background-color: $color-new-accent-yellow;
        color: $color-kinzoo-black;
    }

    &.--grey {
        background-color: $color-grey;
    }

    &.--red {
        background-color: $color-new-accent-red;
    }

    &.--orange {
        background-color: $color-new-accent-orange;
    }

    &.--light {
        background-color: #f3f3f3;
        color: $color-kinzoo-purple;
    }

    &.--green {
        background-color: $color-new-accent-green;
    }

    &.--rounded {
        border-radius: $border-radius;
    }

    &.--icon-only {
        $size: 49px;
        padding: 0;
        background-color: #fff;
        border-radius: 50%;
        width: $size;
        height: $size;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-new-accent-purple;
        font-size: calc-rem(18px);
        line-height: 1;

        &.--large {
            height: 60px;
            width: 60px;
            font-size: 21px;
        }

        &.--purple {
            color: $color-new-accent-purple;
        }

        &.--red {
            color: $color-new-accent-red;
        }

        &.--white {
            color: $color-white;
        }

        &.--orange {
            color: $color-new-accent-orange;
        }

        &.--bg-purple {
            background-color: $color-new-accent-purple;
        }

        &.--bg-red {
            color: $color-white;
            background-color: $color-new-accent-red;
        }

        &.--bg-orange {
            color: $color-white;
            background-color: $color-new-accent-orange;
        }

        &.--bg-green {
            color: $color-white;
            background-color: #2ed199;
        }

        &.--bg-transparent {
            color: $color-white;
            background-color: transparent;
        }

        &.--shadow {
            border: 1px solid #f3f3f3;
            box-shadow: 0px 5px 8px rgb(120 120 120 / 25%);
        }
    }

    &.--bg-btn {
        $size: 26px;
        width: $size;
        height: $size;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    &.--product-btn {
        position: relative;
        // min-height: 80px;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: space-between;
        font-family: $font-heading;
        padding-left: 32px;
        padding-right: 28px;

        .--product-info {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .--title {
                font-size: 19;
                line-height: 1;
                font-weight: 700;
                text-align: left;

                @media (min-width: $bp-tablet-portrait) {
                    font-size: 21px;
                }
            }

            .--sub-text {
                font-weight: 500;
                font-size: 14px;
                line-height: 1;
                margin-top: 8px;
                text-align: left;
            }
        }
        .--product-price-box {
            display: inline-flex;
            flex-direction: column;
            .--product-striked-price {
                font-weight: 700;
                font-size: 14px;
                line-height: 32px;
                text-decoration: line-through;
            }
            .--product-price {
                font-size: 24px;
            }
        }

        .--product-price {
            font-weight: 700;
            font-size: 18px;
            line-height: 32px;

            @media (min-width: $bp-tablet-portrait) {
                font-size: 21px;
            }
        }

        .--product-tag {
            position: absolute;
            min-width: 114px;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $color-new-accent-yellow;
            border: 2px solid $color-white;
            border-top: none;
            border-radius: 0px 0px 10px 10px;
            font-size: 12px;
            font-weight: 700;
            line-height: 10px;
            text-transform: uppercase;
            color: $color-kinzoo-purple;
            text-align: center;
            padding: 4px 9px;
        }
    }
}
