.user-type {
    display: flex;
    justify-content: center;
    column-gap: 20px;

    .__type {
        width: 155px;
        height: 138px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-lighter-grey;
        border-radius: 20px;
        box-shadow: 0px 6px 10px rgba(120, 120, 120, 0.15);
        cursor: pointer;

        &.--active {
            background-color: $color-kinzoo-purple;
            box-shadow: unset;

            .__title {
                color: #ffffff;
            }
        }
    }

    .__img {
        height: 80px;
        width: 80px;
        margin-bottom: u(1);
    }

    .__title {
        color: $color-kinzoo-black;
        font-size: 18px;
        line-height: 21.6px;
        font-family: $font-heading;
        font-weight: 700;
    }
}
