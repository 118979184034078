html {
    font-size: $size-body;
    font-family: $font-body;
    user-select: none;

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: none;
    }

    ::selection {
        // background: mix($color-blue, $color-white, 25%);
    }
}

/*Scroll bar override design*/
/* 
@media (min-width: $bp-tablet-landscape) {

 
    *::-webkit-scrollbar-track {
        background-color: #fafafa;
    }

    *::-webkit-scrollbar {
        width: 12px;
        background-color: #fafafa;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 6px;
    }
} */
