.modal-wrapper {
    background-color: rgba(#000, 0.25);
    bottom: 0;
    height: 100vh;
    left: 0;
    overflow: hidden;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    text-align: center;
    z-index: $z-modal;

    @media (min-width: $bp-tablet-portrait) {
        padding: 0 u(2);
    }

    &--fade {
        opacity: 0;
        transition: opacity $duration-slow;

        .modal {
            transform: translate(0, 50vh);
            opacity: 0;
            transition: transform $duration-slow ease-out, opacity $duration-slow ease-out;
        }
    }

    &--in {
        .modal {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    &--fade.modal-wrapper--in {
        opacity: 1;
    }
}

// Modals stacking
simple-modal-holder {
    $max-stack-size: 5;
    $max-offset: -30px;
    $max-scale: 0.3;

    @for $n from 1 through 10 {
        simple-modal-wrapper:nth-last-child(#{$n}) {
            .modal-wrapper--in .modal {
                @if $n <= $max-stack-size {
                    $i: $max-stack-size - $n; // Count-down
                    $i: $i / ($max-stack-size - 1); // 0.0 - 1.0
                    $i: $i * $i * $i; // "Exponentiate"
                    $i: 1 - $i; // Ease out

                    // margin-top: u(6 - $max-offset * $i);
                    transform-origin: top center;
                    transform: scale(1 - $max-scale * $i) translate(0, $max-offset * $i);
                } @else {
                    display: none;
                }
            }
        }
    }
}
