@mixin active-scale() {
    &:active {
        transform: scale(0.95);
    }
}

@mixin bg() {
    background-size: 450px;
    background-color: $color-white;
}

@mixin bg-star() {
    background-size: 1000px;
    background-color: #041a78;
    background-image: url('/assets/bg-star.jpg');
}

@mixin bg-black() {
    background-size: 1000px;
    background-color: $color-kinzoo-black;
}

@mixin bg-white() {
    background-size: 450px;
    background-color: #fff;
    background-image: url('/assets/bg-white.png');
}

@mixin dialog-stars() {
    @include bg-star;

    border-radius: $border-radius;
    padding: u(4);
    color: $color-white;
}

@mixin flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin text-ellipsis($lines: 1) {
    overflow: hidden;

    @if ($lines ==1) {
        white-space: nowrap;
        text-overflow: ellipsis;
    } @else {
        // Ellipsis on second line
        // See: https://css-tricks.com/almanac/properties/l/line-clamp/
        display: -webkit-box;
        -webkit-line-clamp: $lines;
        -webkit-box-orient: vertical;
    }
}

@mixin scrollbar-v {
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-blue-light;
        border-radius: 5px;
    }
}

@mixin scrollbar-hide {
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
        background-color: transparent;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

@mixin card-width() {
    $width: calc((100vw - var(--offset-padding) - (var(--column-gap) * (var(--card-count) - 0))) / var(--card-count));
    width: $width;
}

@mixin card-height() {
    $height: calc(
        ((100vw - var(--offset-padding) - (var(--column-gap) * (var(--card-count) - 0))) / var(--card-count)) *
            (var(--card-aspect-ratio-multiplier))
    );
    height: $height;
}
