.grid {
    display: grid;
    justify-items: center;

    &--thumbnails {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        grid-gap: u(3) u(2);
    }

    &--contacts {
        grid-template-columns: repeat(auto-fill, 92px);
        grid-gap: 0;
        margin: 0 u(-1);
    }
}
