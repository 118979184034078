.badge {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: calc-re(16px);
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    text-transform: capitalize;
    &.--grey {
        color: $color-kinzoo-black;
        background-color: #f3f3f3;
    }
    &.--green {
        color: $color-white;
        background-color: $color-new-accent-green;
    }
}
