.button-hollow {
    @include flex-center;

    height: 48px;
    border-radius: 10px;
    border: 2px solid $color-blue-light;
    background: transparent;
    padding: 0 u(2);
    cursor: pointer;
    line-height: 1;
    font-size: calc-rem(18px);
    font-family: $font-heading;
    color: $color-white;
    transition: transform $duration-fast;
    user-select: none;
    text-decoration: none !important;
    outline: none;

    &:hover {
        background-color: rgba($color-blue-light, .1);
    }

    &:active {
        transform: scale(0.975);
    }

    &.--small {
        height: 28px;
        min-width: 130px;
        width: max-content;
        border-radius: 6px;
        border-width: 1px;
        font-size: calc-rem(16px);
    }

    &.--blue {
        color: $color-blue-light;
    }

    &.--inline {
        display: inline-flex;
    }

    &--wide {
        width: 100%;
    }

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;

        &,
        &:hover {
            background-color: rgba($color-blue-light, .1);
        }
    }
}
