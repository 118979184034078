.button-dial {
    @include flex-center;

    width: $button-dial-size;
    height: $button-dial-size;
    border-radius: 100%;
    cursor: pointer;
    color: #fff;
    font-size: calc-rem(24px);

    &:active {
        transform: scale(0.975);
    }

    .svg-inline--fa {
        font-size: calc-rem(26px);
    }

    .fa-phone-down {
        font-size: calc-rem(28px);
    }

    &--big {
        width: $button-dial-size-big;
        height: $button-dial-size-big;
    }

    &--disabled {
        &:before,
        &:after {
            content: '';
            position: absolute;
            transform: rotate(-45deg);
            width: 50px;
        }

        &:before {
            height: 8px;
        }

        &:after {
            height: 4px;
            background-color: #fff;
        }
    }

    &--shadow {
        box-shadow: 0px 1.26677px 6.96724px rgba(#000, 0.25);
    }

    // Color Modifiers
    $modifiers: (
        (
            modifier: 'red',
            color: $color-new-accent-red,
        ),
        (
            modifier: 'green',
            color: $color-new-accent-green,
        ),
        (
            modifier: 'blue',
            color: $color-new-accent-light-blue,
        ),
        (
            modifier: 'blue-dark',
            color: $color-new-blue-primary,
        )
    );

    @each $modifier in $modifiers {
        &--#{map-get($modifier, modifier)} {
            &,
            &:before {
                background-color: map-get($modifier, color);
            }

            &:hover,
            &:hover:before {
                background-color: darken(map-get($modifier, color), 3%);
            }
        }
    }
}
