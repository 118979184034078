$unit: 8px; // Unit for padding/margins, always use multiples of $unit

$size-body: 16px; // HTML font-size = 1rem
$size-body-mobile: 16px; // HTML font-size (mobile)
$size-h1: 32px; // H1 size, interpolate H2-H5 sizes, H6 will be equal to $font-size
$size-small: 14px;
$size-smaller: 12px;
$size-tiny: 10.5px;

// Together Font + System Font Stack
$font-heading: 'Museo Sans', sans-serif;
$font-body: 'Museo Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-monospace: 'Source Code Pro', monospace;

$weight-thin: 300;
$weight-normal: normal;
$weight-bold: bold;

$color-black: #333;
$color-blue: #1115d2;
$color-blue-dark: #052a8c;
$color-blue-light: #0c80f2;
$color-blue-pale: #4e92df;
$color-green: #70d612;
$color-grey: #ddd;
$color-orange: #ff9511;
$color-red: #e6204b;
$color-red-shade: #b11a3a;
$color-white: #fff;

$color-new-black: #313440;
$color-new-black-60: rgba($color-new-black, 0.6);
$color-new-black-40: rgba($color-new-black, 0.4);
$color-new-black-20: rgba($color-new-black, 0.2);
$color-new-grey-subheader: #949494;
$color-new-grey-subheader-40: rgba($color-new-grey-subheader, 0.4);
$color-new-grey: rgba(#d8d8d8, 0.4);
$color-new-grey-outline: #efefef;
$color-new-grey-navigation: #f5f6f9;
$color-new-grey-featured: rgba(#e6e9f1, 0.4);
$color-new-white: #fff;
$color-new-blue-primary: #3e57f0;
$color-new-blue-primary-10: rgba($color-new-blue-primary, 0.1);
$color-new-blue-secondary: #eceffe;
$color-new-accent-soft-pink: #f4c4cb;
$color-new-accent-purple: #521baf;

//Accent colors
$color-new-accent-light-blue: #55c9ff;
$color-new-accent-green: #2ed199;
$color-new-accent-red: #f1485b;
$color-new-accent-pink: #f95794;
$color-new-accent-orange: #ff7f57;
$color-new-accent-yellow: #fcdb56;

//Primary Colors
$color-kinzoo-purple: #521baf;
$color-lilac-purple: #834de0;
$color-shade-purple: #ece1ff;
$color-light-purple: #ede6fb;
$color-dark-purple: #2a0667;
$color-kinzoo-black: #151618;
$color-tech-grey: #9c9c9d;
$color-grey: #bdbdbd;
$color-menu-grey: #d0d0d0;
$color-menu-text-grey: #8f8f8f;
$color-lighter-grey: #f3f3f3;
$color-shade-grey: #787878;
$color-dark-grey: #6f7071;
$color-darkest-grey: #424344;
//

$duration-fast: 50ms;
$duration: 150ms;
$duration-slow: 300ms;
$duration-snail: 750ms;

$bp-phone-portrait: 320px;
$bp-phone-small: 385px; // Mobile-small-device cutoff
$bp-phone-landscape: 480px; // Mobile-landscape (and larger)
$bp-tablet-portrait: 768px; // Tablet-portrait (and larger)
$bp-tablet-landscape: 992px; // Tablet-landscape (and larger)
$bp-desktop: 1200px; // Laptops (and langer)

//height bp
$bp-phone-landscape-height: 690px;
$z-activity: 8;
$z-content: 8;
$z-pdf-loading: 9;
$z-pdf-controls: 9;
$z-navigation: 9;
$z-settings-title: 9;
$z-layout-backdrop: 90;
$z-layout-contacts: 95;
$z-layout-call-button: 95;
$z-toast-banner: 99;
$z-call-container: 100;
$z-not-your-turn: 150;
$z-activity-loading: 200;
$z-activity-close: 210;
$z-pdf-overlay: 210;
$z-favorite-see-all-modal: 299;
$z-video-container: 300;
$z-photobooth-container: 302;
$z-video-reconnecting: 310;
$z-springboard-button: 320;
$z-requesting-play-time: 320;
$z-photobooth-button: 320;
$z-controls-backdrop: 400;
$z-controls: 410;
$z-dialog: 999;
$z-modal: 1000;
$z-dialog-over-the-modal: 1001;
$z-wait-dialog: 9999;
$z-payment-modal: 10000;

// Presets
$border-radius: 12px;
$box-shadow: 0 0 5px rgba($color-black, 0.25);
$button-dial-size: 60px;
$button-dial-size-big: 83px;

$contact-avatar-size: 70px;
$contact-border-size: 2px;
$contact-width: 92px;

$modal-border-radius: 10px;
$modal-top-margin: u(6);
$modal-wide-width: 587px;
$modal-ultra-wide-width: 680px;
$modal-ultra-wide-xl-width: 790px;
$modal-padding-sm: u(3);
$modal-padding-lg: u(4);

$thumb-lg-width: 160px;
$thumb-lg-height: 120px;
$thumb-sm-width: 120px;
$thumb-sm-height: 90px;

$layout-avatar-margin-top: u(4);
$layout-avatar-size: 72px;

$tablet-container-padding-left: 60px;
$tablet-container-padding-right: 60px;
$mobile-container-padding-left: 15px;
$mobile-container-padding-right: 15px;

:root {
    --blue: #1e90ff;
    --offset-padding: 0;
    --card-count: 2;
    --column-gap: 16px;
    --card-aspect-ratio-multiplier: 1.25;
}

:root {
    --ion-color-kinzoo-purple: #521baf;
    --ion-color-kinzoo-purple-rgb: 82, 27, 175;
    --ion-color-kinzoo-purple-contrast: #ffffff;
    --ion-color-kinzoo-purple-contrast-rgb: 255, 255, 255;
    --ion-color-kinzoo-purple-shade: #48189a;
    --ion-color-kinzoo-purple-tint: #6332b7;
}

.ion-color-kinzoo-purple {
    --ion-color-base: var(--ion-color-kinzoo-purple);
    --ion-color-base-rgb: var(--ion-color-kinzoo-purple-rgb);
    --ion-color-contrast: var(--ion-color-kinzoo-purple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-kinzoo-purple-contrast-rgb);
    --ion-color-shade: var(--ion-color-kinzoo-purple-shade);
    --ion-color-tint: var(--ion-color-kinzoo-purple-tint);
}
