@import 'core';
@import 'core';

.feedback {
    &__container {
        @media (min-width: $bp-tablet-portrait) {
            padding: 0 68px;
        }

        .form {
            margin-top: 40px;

            textarea {
                font-weight: 500;
            }
        }
    }

    &__row {
        margin-bottom: 28px;
    }

    &__label {
        color: $color-kinzoo-black;
        font-size: 15px;
        line-height: 23px;
        font-weight: 500;
        margin-bottom: 16px !important;
        text-align: center !important;
    }
}
