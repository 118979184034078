.page {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    overflow: auto;

    @media (min-width: $bp-tablet-portrait) {
        display: flex;
        flex-direction: column;
    }

    &__content {
        @include bg;

        flex-grow: 1;
        padding: u(3) 0;
        overflow-y: auto;

        .section__title-wrapper {
            margin-right: u(2);
        }

        .section__title {
            padding: 0 u(2);
        }
    }

    &--safe {
        .page__content {
            padding-bottom: $thumb-sm-height + u(3) !important;

            @media (min-width: $bp-tablet-portrait) {
                padding-bottom: $thumb-lg-height + u(3) !important;
            }
        }
    }
}
